import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { SECTION_USER_DETAILS } from "constants/common";
import styled from "styled-components";
import { useAppSelector } from "hooks/redux-hooks";
import { selectSelectedCustomerDetails } from "./slice/selectors";
import Container from "@mui/material/Container";

interface CustomerDetailsProps { }

const CustomerDetailsContainer = styled.div`
  background-color: #fff;

  & .MuiContainer-root {
    padding: 0 20px;
    width: inherit;
  }
`;

const CardContainer = styled(Container)`
  padding: 0;
  width: inherit;

  & .input-container {
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    transform: translateY(-8px);
    margin-bottom: 8px;

    & .MuiTypography-root {
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
      width: 80px;
    }

    & .MuiInput-root {
      line-height: 18px;
      font-size: inherit;
      font-weight: inherit;
      min-width: 300px;
    }

    & .input {
      font-size: 12px;
      border-color: #f7446d;
    }
  }

  & .hide {
    display: none;
  }

  & .field-title {
    width: 120px;
    color: #959493;
  }

  & .top-section {
    padding-block-start: 23px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .Mui-disabled {
    -webkit-text-fill-color: var(--primary-text-color);

    :before {
      border-bottom-style: none;
    }
  }

  & .MuiButtonBase-root {
    padding: 0;
  }

`;

const CustomerDetails: React.FC<CustomerDetailsProps> = () => {
  const {
    email,
    dateOfBirth,
    phone,
    address,
  } = useAppSelector(selectSelectedCustomerDetails);

  const { street, city, country, state, zip } = address;

  const addressForDisplay =
    street && city && state && country && zip
      ? `${street}, ${city}, ${state}, ${country}, ${zip}`
      : "";




  return (
    <CustomerDetailsContainer className={SECTION_USER_DETAILS}>
      <CardContainer>
        <div className="top-section">
          <Box className="input-container">
            <Typography component="label" className="field-title">
              Email
            </Typography>
            <TextField
              placeholder="fill email"
              variant="standard"
              className="input"
              value={email}
              disabled
              error={email === ""}
            />
          </Box>
          <Box className="input-container">
            <Typography component="label" className="field-title">
              DoB
            </Typography>

            <TextField
              placeholder="fill date of birth"
              value={dateOfBirth}
              variant={"standard"}
              disabled
              error={dateOfBirth === ""}
            />
          </Box>
          <Box className="input-container">
            <Typography component="label" className="field-title">
              Address
            </Typography>
            <TextField
              placeholder="fill address"
              variant="standard"
              className="input"
              value={addressForDisplay}
              disabled
              error={addressForDisplay === ""}
            />
          </Box>
          <Box className="input-container">
            <Typography component="label" className="field-title">
              Phone
            </Typography>
            <TextField
              placeholder="fill phone"
              variant="standard"
              className="input"
              value={phone}
              disabled
              error={phone === ""}
            />
          </Box>
        </div>
      </CardContainer>
    </CustomerDetailsContainer>
  );
};
export default CustomerDetails;
