import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Avatar from "components/ui/Avatar";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { selectSelectedCustomerDetails } from "./slice/selectors";
import { ReactComponent as Pencil } from "assets/svg/Pencil.svg";
import { openEditCustomerModal } from "./slice";


const HeaderStyle = styled("header")({
  width: "100%",
  position: "sticky",
  top: 0,
  backgroundColor: "#fff",
  zIndex: 1,
  padding: "23px max(calc((100% - 1200px) / 2), 12px)",
  display: "flex"
});

const AvatarStyle = styled("div")({
  width: "87px",

  "& div.avatar": {
    borderRadius: "50%",
    height: "60px",
    width: "60px",
    boxShadow: "5px 5px 5px #ccc",
    display: "flex",
    backgroundColor: "#bdbdbd",

    "& .avatar_image": {
      borderRadius: "50%",
      margin: "auto",
      width: "100%",
      height: "100%"
    },

    "& .avatar_name": {
      borderRadius: "50%",
      margin: "auto",
      color: "#ffffff"
    },

    "& .avatar_name-container": {
      height: "60px",
      width: "60px"
    }
  }
});

const NameStatusStyle = styled("div")({
  flex: 8,
  "& .name": {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#434140"
  },
  "& .trip-status-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "32px"
  },

  "& .status-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 8px",
    borderRadius: "12px",
    width: "58px",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "13px",
    color: "#ffffff",
    whiteSpace: "nowrap"
  },
  "& .pre-trip": {
    background: "var(--trip-status-pre-trip)",
    textTransform: "capitalize"
  },

  "& .in-trip": {
    background: "var(--trip-status-in-trip)",
    textTransform: "capitalize"
  },

  "& .post-trip": {
    background: "var(--trip-status-post-trip)",
    textTransform: "capitalize"
  },

  "& .lastLogin": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "100%",
    color: "#999999"
  }

});


const CustomerHeader: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const {
    firstName,
    lastName,
    email,
    dateOfBirth,
    lastLogin,
    phone,
    tripStatus,
    address,
    profilePicture
  } = useAppSelector(selectSelectedCustomerDetails);
  const [isVisible, setIsVisible] = useState(true);


  useEffect(() => {
    const policyViewComponent = window.document.getElementById("policy-view");
    if (!policyViewComponent) return;

    let animationFrame = 0;


    const listenToScroll = () => {
      if (animationFrame) {
        return;
      }

      animationFrame = window.requestAnimationFrame(() => {
        const scrollTop = policyViewComponent.scrollTop;

        if (scrollTop && scrollTop > 140) {
          isVisible && setIsVisible(false);
        } else {
          !isVisible && setIsVisible(true);
        }

        animationFrame = 0;
      });
    };

    policyViewComponent.addEventListener(
      "scroll",
      listenToScroll
    );

    return () => {
      if (animationFrame) {
        window.cancelAnimationFrame(animationFrame);
      }

      policyViewComponent.removeEventListener(
        "scroll",
        listenToScroll
      );
    }
  }, [isVisible, dispatch]);

  const tripStatusForDisplay = tripStatus
    ? tripStatus.replaceAll("-", " ")
    : "";

  const openCustomerModalEdit = () => {
    dispatch(
      openEditCustomerModal({
        firstName,
        lastName,
        email,
        dateOfBirth,
        lastLogin,
        phone,
        tripStatus,
        address,
        profilePicture
      })
    );
  };

  return (
    <HeaderStyle>
      <AvatarStyle>
        <Avatar
          isMainAvatar={true}
          imgUrl={profilePicture.url ? profilePicture.url : ""}
          name={`${firstName} ${lastName} `}
        />
      </AvatarStyle>
      <NameStatusStyle>
        <div className="trip-status-container">
          <div className={`status-container ${tripStatus} `}>
            <div>{tripStatusForDisplay}</div>
          </div>
          {isVisible && (
            <IconButton className="btn-edit" onClick={openCustomerModalEdit}>
              <Pencil />
            </IconButton>
          )}
        </div>

        <div className="name">
          {firstName} {lastName}
        </div>
        <span className="lastLogin">Last app login: {lastLogin}</span>
      </NameStatusStyle>
    </HeaderStyle>

  );
};
export default CustomerHeader;
