import React, { useEffect, useState } from "react";
import PolicyView from "components/policies/PolicyView";
import SpinnerLoader from "components/ui/loaders/SpinnerLoader/SpinnerLoader";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { selectSelectedPolicy } from "components/policies/slice/selectors";
import { setSelectedPolicy } from "components/policies/slice";
import PolicyModalsContainer from "../components/policies/modals";
import { searchPolicyPage } from "components/policies/slice/thunks";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

interface PolicyPageProps {
  policyCode: string
}

const NoPolicyStyle = styled("div")({
  textAlign: "center",
  "& p": {
    fontSize: "18px"
  },
  "& a": {
    color: "var(--primary-color)",
    textDecoration: "none"
  }
});

const PolicyStyle = styled("div")({
  position: "relative"
});




const PolicyPage: React.FC<PolicyPageProps> = ({ policyCode }) => {
  const selectedPolicy = useAppSelector(selectSelectedPolicy);
  const dispatch = useAppDispatch();
  const [policyFound, setPolicyFound] = useState<Boolean>();

  useEffect(() => {
    searchPolicyPage(policyCode, 0)
      .then(({ policies }) => {
        if (policies && policies[0]) {
          //the requested policy can be either 
          //the main policy or a related policy
          const requestedPolicy = [
            policies[0],
            ...(policies[0].relatedPolicies || [])
          ].find(policy =>
            policy.code.toLowerCase() === policyCode.toLowerCase());
          if (requestedPolicy) {
            dispatch(setSelectedPolicy(requestedPolicy));
          } else {
            setPolicyFound(false);
          }
        } else {
          setPolicyFound(false);
        }
      });

  }, [dispatch, policyCode])


  if (policyFound === false)
    return (
      <NoPolicyStyle>
        <p>
          Couldn't find Policy {policyCode}
        </p>
        <Link to="/policies">return to Policies screen</Link>
      </NoPolicyStyle>
    );


  return (
    <PolicyStyle>
      {
        !!selectedPolicy ? <PolicyView /> : <SpinnerLoader isLoading={true} />
      }
      <PolicyModalsContainer />
    </PolicyStyle>
  );
};

export default PolicyPage;
