import React from "react";
import WestIcon from "@mui/icons-material/West";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const DrawerStyle = styled("aside")<Pick<DrawerProps, "anchor">>(({ anchor }) => ({
    boxSizing: "border-box",
    backgroundColor: "var(--modal-background-color)",
    position: "fixed",
    width: "calc(100% - 48px)",
    insetInlineEnd: 0,
    height: "100%",
    zIndex: 10,
    transition: "transform 0.3s",
    transform: `${(function () {
        const direction = anchor && ["bottom", "top"].includes(anchor) ?
            "Y" : "X";
        const size = anchor && ["left", "top"].includes(anchor) ?
            "-100" : "100";
        const viewport = anchor && ["bottom", "top"].includes(anchor) ?
            "vh" : "vw";
        return `translate${direction}(${size + viewport}) scale${direction}(0)`;
    })()}`,
    "&.opened": {
        transform: "translate(0)",
        transition: "transform 0.3s"
    }
}));

const HeaderStyle = styled("header")({ padding: "18px 23px 0" });
const BodyStyle = styled("div")({ overflowY: "auto", height: "100%" });


interface DrawerProps {
    anchor?: "left" | "top" | "right" | "bottom",
    opened: boolean,
    onClose?: Function,
    children: React.ReactNode
}

const Drawer: React.FC<DrawerProps> = (props: DrawerProps) => {
    const { anchor, opened, onClose, children } = props;

    return (
        <DrawerStyle className={opened ? "opened" : ""} anchor={anchor}>
            <HeaderStyle>
                <Button
                    className="btn-back"
                    startIcon={<WestIcon />}
                    size="small"
                    variant="text"
                    onClick={() => onClose && onClose()}>
                    Back
                </Button>
            </HeaderStyle>
            <BodyStyle>{children}</BodyStyle>
        </DrawerStyle>
    );
};

export default Drawer;
