import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  LOGIN_URL,
  NOT_FOUND_404_URL,
  CLAIMS_URL,
  USER_POLICIES_URL,
  POLICIES_URL,
  ADVISORS_URL,
  DASHBOARD,
  WIDGETS
} from "./constants";
import NotFound404 from "./404";
import Login from "./Login";
import Claims from "./Claims";
import Policies from "./Policies";
import Advisors from "./Advisors";
import DashboardPage from "./Dashboard";
import WidgetsPage from "./Widgets";
import PolicyPage from "./Policy";

const Routes = () => {
  return (
    <Switch>
      <Route exact path={LOGIN_URL}>
        <Login />
      </Route>
      <Route exact path={CLAIMS_URL}>
        <Claims />
      </Route>
      <Route exact path={POLICIES_URL}>
        <Policies />
      </Route>
      <Route exact path={USER_POLICIES_URL}
        render={
          ({ match }) => (<PolicyPage policyCode={match.params.code} />)
        }
      />
      <Route exact path={ADVISORS_URL}>
        <Advisors />
      </Route>
      <Route exact path={DASHBOARD}>
        <DashboardPage />
      </Route>
      <Route exact path={WIDGETS}>
        <WidgetsPage />
      </Route>
      <Route exact path={NOT_FOUND_404_URL}>
        <NotFound404 />
      </Route>
      <Redirect to={LOGIN_URL} />
    </Switch>
  );
};

export default Routes;
