import React, { useCallback, useEffect } from "react";
import PoliciesList from "components/policies/PoliciesList";
import PolicyView from "components/policies/PolicyView";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { displayPolicy } from "components/policies/slice/selectors";
import Drawer from "components/ui/Drawer";
import {
  resetDocumentsCreationStatuses,
  resetSelectedPolicy,
  setDisplayPolicy
} from "components/policies/slice";
import PolicyModalsContainer from "../components/policies/modals";

interface PoliciesPageProps { }

const PoliciesPage: React.FC<PoliciesPageProps> = () => {
  const open = useAppSelector(displayPolicy);
  const dispatch = useAppDispatch();
  const handleClose = useCallback(() => {
    dispatch(resetDocumentsCreationStatuses());
    dispatch(resetSelectedPolicy());
    dispatch(setDisplayPolicy(false));
  }, [dispatch]);

  useEffect(() => handleClose, [handleClose]);

  return (
    <>
      <Drawer opened={open} onClose={handleClose} anchor="right">
        <PolicyView />
      </Drawer>
      <PoliciesList />
      <PolicyModalsContainer />
    </>
  );
};

export default PoliciesPage;
